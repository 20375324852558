import React from 'react';
import {
  Flex,
  Accordion,
  AccordionItem,
  AccordionHeader,
  Box,
  AccordionIcon,
  AccordionPanel,
  Text,
  Divider,
  Stack,
  Link as UILink,
} from '@chakra-ui/core';
import { Link } from 'react-router-dom';

import Socials from './Socials';
import SectionLayout from '../../layouts/SectionLayout';
import Contact from './Contact';

interface IItem {
  name: string;
  url: string;
}

interface IFooterAccordionItem {
  title: string;
  items: IItem[];
}

const rows = {
  readeo: [
    { url: `/about`, name: 'About us' },
    { url: `/library`, name: 'Library' },
    { url: `/pricing`, name: 'Pricing' },
    { url: `https://readeo.com/blog`, name: 'Blog' },
    // { url: `/guest/signup`, name: 'Guest account' },
    // { url: `/guest/signup`, name: 'Press' },
  ],
  help: [
    { url: `/faq`, name: 'Support & FAQ' },
    { url: `/terms-of-service`, name: 'Terms of service' },
    { url: `/privacy`, name: 'Privacy' },
    // { url: '/newsletter', name: 'Newsletter' },
  ],
};

function LinkItem({ url, name }: IItem) {
  const isHref = url.includes('http://') || url.includes('https://');

  const linkText = (
    <Text
      py={1}
      color="white"
      fontWeight="bold"
      fontSize="1rem"
      letterSpacing="0.04em"
      opacity={{ base: 0.7, md: 1 }}
    >
      {name}
    </Text>
  );

  if (isHref) {
    return <a href={url}>{linkText}</a>;
  }

  return <Link to={url}>{linkText}</Link>;
}

// Only to be rendered on mobile
function FooterAccordionItem(props: IFooterAccordionItem) {
  return (
    <AccordionItem borderColor="gray.400">
      <AccordionHeader paddingY={4}>
        <Box fontWeight="bold" flex="1" textAlign="left" color="white">
          {props.title}
        </Box>
        <AccordionIcon color="#ea712d" size="1.875rem" />
      </AccordionHeader>
      <AccordionPanel pb={4} flexDirection="column">
        {props.items.map((item) => (
          <LinkItem {...item} />
        ))}
      </AccordionPanel>
    </AccordionItem>
  );
}

// Only to be rendered on desktop
function FooterColumn(props: IFooterAccordionItem) {
  return (
    <Box mr="20%" display={{ base: 'none', md: 'block' }}>
      <Text
        // opacity={0.5}
        fontWeight="bold"
        fontSize="md"
        letterSpacing="0.04em"
        color="#4D87CA"
      >
        {props.title}
      </Text>
      {props.items.map((item) => (
        <LinkItem {...item} />
      ))}
    </Box>
  );
}

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <SectionLayout
      flexDirection="column"
      backgroundColor="brand.secondary"
      py={{ md: '2rem' }}
      color="white"
      height={{ md: '292px' }}
      // borderTop="3px solid #00D4FF"
    >
      <Flex
        display={{ base: 'block', md: 'flex' }}
        width="100%"
        px={{ md: '0.5rem' }}
        mb="1rem"
      >
        {/* display on mobile */}
        <Accordion display={{ md: 'none' }} allowMultiple>
          <FooterAccordionItem title="Readeo" items={rows.readeo} />
          <FooterAccordionItem title="Help" items={rows.help} />
        </Accordion>
        {/* mobile */}

        {/* display on desktop */}
        <>
          <FooterColumn title="Readeo" items={rows.readeo} />
          <FooterColumn title="Help" items={rows.help} />
        </>
        {/* desktop */}

        <Box p={{ base: 3, md: 0 }}>
          <Contact />
          <Socials />
        </Box>
      </Flex>
      <Divider />
      <Stack
        spacing={8}
        fontSize="0.8125rem"
        lineHeight="1.875rem"
        letterSpacing="0.04em"
        flexDirection={{ base: 'column-reverse', md: 'row' }}
        p={{ base: '0 0 0.5rem 0.75rem', md: '0 0.5rem' }}
        isInline
      >
        <Text>&copy; {year} Readeo, LLC. All rights reserved.</Text>
        {[
          { url: '/copyright', name: 'Copyright' },
          { url: '/terms-of-service', name: 'Terms of service' },
          { url: '/privacy', name: 'Privacy' },
          { url: 'https://readeo.com/blog', name: 'Blog' },
          { url: '/patent', name: 'Patent' },
        ].map((item) =>
          item.url.includes('https://') ? (
            <UILink href={item.url} isExternal>
              {item.name}
            </UILink>
          ) : (
            <UILink
              key={item.name}
              to={item.url}
              //@ts-ignore
              as={Link}
            >
              {item.name}
            </UILink>
          ),
        )}
      </Stack>
    </SectionLayout>
  );
}
