import React from 'react';
import { Box, Flex, Grid, Image, Text, useDisclosure } from '@chakra-ui/core';

import { useIsMobileView } from 'hooks/useMobileView';
import { useIsLargeTabletView } from 'hooks/useIsLargeTabletView';

import candleWickLogo from 'assets/images/candlewick.png';
import leanerLogo from 'assets/images/lerner.png';
import chronicleBooksLogo from 'assets/images/chronicle.png';
import familiusLogo from 'assets/images/familius-logo.png';
import LittleGestaltenLogo from 'assets/images/little-gestalten.png';
import bookCluster from 'assets/images/book-cluster.jpg';
import leftDiagonals from 'assets/images/left_diagonals.svg';
import rightDiagonals from 'assets/images/right_diagonals.svg';
import leftCandyCane from 'assets/images/left_candy_cane.svg';
import rightCandyCane from 'assets/images/right_candy_cane.svg';
import redRect from 'assets/images/red_rect.svg';

import GoldPlanFeatures from './GoldPlanFeatures';
import ChoosePlanDesktop from './ChoosePlanDesktop';
import PlanDescriptionMobile from './PlanDescriptionMobile';
import PricingPlansMobile from './PricingPlansMobile';
import PricingPlansMobilePromo from './PricingPlanMobilePromo';

import { StyledButton } from 'styles/StyledButton';
// import config from 'config';

export default function PricingPlans() {
  const isMobile = useIsMobileView();
  const isLargeTablet = useIsLargeTabletView();
  const [showMobilePlan, setShowMobilePlan] = React.useState(false);
  const limitedFreePlanDisclosure = useDisclosure();

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    return;
  };

  const handleClick = () => {
    if (isMobile) setShowMobilePlan(true);
  };

  const togglePromotionDetails = process.env.REACT_APP_SALES_BANNER === 'show';

  if (showMobilePlan) {
    return (
      <Box>
        {togglePromotionDetails ? (
          <PricingPlansMobilePromo />
        ) : (
          <PricingPlansMobile />
        )}
      </Box>
    );
  }

  return (
    <Box>
      <Flex
        justifyContent={'center'}
        background={'white'}
        pt={{ base: 12, md: 16 }}
        // pb={{ base: 3, md: 6 }}
        flexDir="column"
      >
        <Box background={'white'} py={isMobile ? '1rem' : '2rem'} px="1.5rem">
          <Text
            fontWeight="600"
            fontSize={isMobile ? '24px' : '30px'}
            lineHeight="27px"
            textAlign="center"
            color="#1E4786"
            mb="8px"
          >
            Enjoy (way) more with Readeo Gold
          </Text>
          <Text
            lineHeight={isMobile ? '22px' : '24px'}
            textAlign="center"
            fontSize="16px"
          >
            Take advantage of our Gold Family Plan option to experience all the
            benefits Readeo has to offer.
          </Text>
        </Box>

        {togglePromotionDetails && (
          <>
            {isMobile && (
              <Flex
                background={`url(${redRect}) center/100% 100% no-repeat white`}
              >
                <Image src={leftDiagonals} alt="left candy cane" />

                <Text
                  fontWeight={700}
                  lineHeight="24px"
                  textAlign="center"
                  fontSize="16px"
                  color="#EEEEEE"
                  whiteSpace="nowrap"
                  // marginLeft="-0.6rem"
                >
                  50% off Gold Plans during
                  <br />
                  our holiday sale. Ends Dec 31.
                </Text>
                <Image src={rightDiagonals} alt="right candy cane" />
              </Flex>
            )}
            {!isMobile && (
              <Flex
                justifyContent="center"
                alignItems="center"
                marginTop={'-1rem'}
              >
                <Image src={leftCandyCane} alt="left candy cane" />
                <Text fontWeight={600} lineHeight="24px">
                  Limited time holiday prices*
                </Text>
                <Image src={rightCandyCane} alt="right candy cane" />
              </Flex>
            )}
          </>
        )}
      </Flex>

      {isMobile ? (
        <PlanDescriptionMobile
          showPlanMobile={handleClick}
          setIsOpen={() => limitedFreePlanDisclosure.onOpen()}
        />
      ) : (
        <ChoosePlanDesktop />
      )}
      <Box as={Flex} alignItems="center" flexDirection="column">
        <Box
          height={{ base: '100%', lg: '500px', xl: '620px' }}
          backgroundImage={`url(${bookCluster})`}
          width="100%"
          backgroundSize={isMobile || !isLargeTablet ? '100% 100%' : '50% 100%'}
          backgroundRepeat="no-repeat"
          display="flex"
          alignItems="center"
          justifyContent={isMobile || !isLargeTablet ? 'center' : 'flex-end'}
        >
          <Box
            height={isMobile ? '420px' : !isLargeTablet ? '600px' : 'inherit'}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent={'center'}
            width={isMobile ? 'inherit' : !isLargeTablet ? '70%' : '50%'}
          >
            <Flex
              justifyContent="center"
              alignItems={'flex-start'}
              flexDirection="column"
              height={isMobile ? '300px' : 'initial'}
              width={isMobile ? '360px' : 'initial'}
              opacity={isMobile ? 0.94 : 1.0}
              background="#FFFFFF"
              id="ddd"
              p={isMobile ? '0px 2rem' : !isLargeTablet ? '2rem' : 'initial'}
              mx={isMobile || !isLargeTablet ? 'initial' : '64px'}
            >
              <Box>
                {!isMobile ? (
                  <>
                    <Text
                      color="#083261"
                      fontWeight="600"
                      fontSize="1.875rem"
                      lineHeight="21px"
                    >
                      Fill your shelves
                    </Text>
                    <Text
                      fontSize="1.125rem"
                      color="#3D3D3D"
                      fontFamily="Montserrat"
                      lineHeight="24px"
                      paddingY="1em"
                    >
                      Readeo Gold Plans offer access to our full library — over
                      1,000 books (and counting)! New books are added every week
                      from our award-winning publishers:
                    </Text>
                    <Grid
                      justifyContent="space-between"
                      width="100%"
                      mt="8px"
                      gridTemplateColumns="repeat(3, 1fr)"
                      gap={12}
                      py="1.5rem"
                    >
                      <img
                        alt=""
                        src={candleWickLogo}
                        style={{ objectFit: 'cover' }}
                      />
                      <img
                        alt=""
                        src={chronicleBooksLogo}
                        style={{ objectFit: 'cover' }}
                      />
                    </Grid>
                    <Grid
                      justifyContent="space-between"
                      width="100%"
                      mt="8px"
                      gridTemplateColumns="repeat(3, 1fr)"
                      gap={12}
                      py="1.5rem"
                    >
                      <img
                        alt=""
                        src={leanerLogo}
                        style={{ objectFit: 'cover' }}
                      />
                      <img
                        alt=""
                        src={LittleGestaltenLogo}
                        style={{ objectFit: 'cover' }}
                      />
                      <img
                        alt=""
                        src={familiusLogo}
                        style={{ objectFit: 'cover' }}
                      />
                    </Grid>
                  </>
                ) : (
                  <Box>
                    <Text
                      fontSize="1.5rem"
                      color="brand.blue"
                      fontWeight="600"
                      fontFamily="Montserrat"
                    >
                      So Many Books
                    </Text>
                    <Text lineHeight="22px" fontFamily="Montserrat">
                      On a Readeo Gold Plan you will have access to our full
                      library! We have over 1,000 books and add new books every
                      week!
                    </Text>
                  </Box>
                )}
              </Box>
              <Box width={{ base: '100%', md: '205px' }}>
                <StyledButton
                  size="sm"
                  textAlign="center"
                  width="98%"
                  height="50px"
                  alignSelf="left"
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="13pt"
                  _hover={{
                    background:
                      'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #f38530',
                  }}
                  // onClick={() =>
                  //   (window.location.href = `${config.appUrl}/signup`)
                  // }
                  onClick={scrollToTop}
                >
                  Start Reading
                </StyledButton>
              </Box>
            </Flex>
          </Box>
        </Box>
        <GoldPlanFeatures />
      </Box>
    </Box>
  );
}
