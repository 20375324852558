interface FAQItem {
  title: string;
  text: string;
}
interface FAQSection {
  headingText: string;
  items: FAQItem[];
}

type FAQData = FAQSection[];

export const faqData: FAQData = [
  {
    headingText: 'Membership',
    items: [
      {
        title: 'How do I join Readeo?',
        text: `It's easy! Just go to Readeo.com and click “Join"`,
      },
      {
        title:
          'If I have an account, and someone else wants to BookChat, do they also need a Readeo account?',
        text: `Yes. Everyone you’d like to BookChat with will need a Readeo account.`,
      },
      {
        title: 'Once I have joined, how do I get started?',
        text: `<div>Invite people you'd like to be your BookChat buddies!
        <ul style="margin-left: 2rem">
          <li>Log in to your Readeo account.</li>
          <li>
            Click on the <strong>"Add BookChat Buddy"</strong> button on the left side of your home profile screen and enter their emails, or copy your unique link provided to share with your friends and family however you’d like—in a text or a post on social media. Either option will invite that person to create a Free account to join/begin a BookChat with you.
          </li>
          <li>
            Once they’ve set up their own free account, and are logged in, you will appear in each others' BookChat buddies lists. You may need to approve a pending friend request if you opted for sending out the custom link.
            </li>
          <li>
            People who are logged into their Readeo accounts will show up as "online" with a green star next to their name. You can click on their name to invite them to join your BookChat!
          </li>
        <ul>
        <div>`,
      },
      {
        title: 'What do I get with a Gold Family Plan?',
        text: `With our Gold Family Plan you can gift a Gold Plan membership to up to four other people for FREE — giving them unlimited access to our entire library! Anyone in your Family Plan can read solo or together during a BookChat. Plus, each member of your Family Plan can invite other members outside of the Family Plan to BookChat with them for FREE. In other words, a BookChat buddy can either be part of a paid membership (full benefits) or a Free membership (limited benefits). Learn more about our plans <a style="color:midnightblue; font-weight: bold" href="https://www.readeo.com/pricing">here.</a>`,
      },
      {
        title: 'How many people can join a Gold Family Plan?',
        text: `You can invite up to four other people to enjoy a Readeo Gold membership for free by clicking on the <strong>Add a BookChat Buddy</strong> tab. From there you can enter their email address, or copy your custom link, and they’ll get an email from Readeo Support or be directed to a website inviting them to become a member on your account. Subscriptions include five members total (the paying member plus four others) who have full member benefits within the Readeo platform. Learn more about our plans  <a style="color:midnightblue; font-weight: bold" href="https://www.readeo.com/pricing">here.</a>`,
      },
      {
        title: 'What’s the difference between a monthly and yearly membership?',
        text: `Compared to our monthly membership, annual will save you 17%. You can view our <a style="color:midnightblue; font-weight: bold" href="https://www.readeo.com/pricing">pricing options</a>.`,
      },
      {
        title: 'I am having trouble joining, what should I do?',
        text: `
        <div>
          Here are a few tips that could be helpful:
          <ul style="margin-left: 2rem">
            <li>
              Keep in mind the password requirements: 8 characters, 1 uppercase letter, 1 number and/or special character.
            </li>
            <li>
              If you’re still having issues you may want to try accessing Readeo from a different device or a different Wi-Fi connection. 
            </li>
          </ul>
        <div>
        `,
      },
      {
        title: 'What’s the difference between a paid member and a free member?',
        text: `Paid members have unlimited access to the award-winning Readeo library and our patented BookChat. Free members  have access to five free books (swapped out weekly) to read solo or together with another user in a BookChat, but they will not have access to the full  library unless they upgrade to one of our paid plans.`,
      },
      {
        title: `How do I know if one of my BookChat buddies is a member of my Gold Family Plan?`,
        text: `Click on the <strong>"Profile"<strong> tab in the upper right-hand corner of your home page and then <strong>“My Account”</strong> in the dropdown. Under your account details over on the left of the screen, click <strong>“My Family Plan Members”</strong> where you can see their status and upgrade or downgrade them.`,
      },
      {
        title: 'Can a free member BookChat with other Free members?',
        text: `
         <div>
            Yes, but Free members can only read our 5 free books (swapped out weekly) or preview the first few pages of other books in our library before they’ll be prompted to upgrade. To access the full benefits of Readeo, including the Readeo library, users  can
            <a style="color:midnightblue; font-weight: bold" href="https://www.readeo.com/pricing">
             sign up for a paid membership account
            </a>
         <div>
        `,
      },
      {
        title: 'How do Free members  upgrade if they want to?',
        text: `If you want to start a membership, you can log in to your account, then click on the <strong>"Profile"</strong> button in the top right-hand corner. Then, click on <strong>“My Account”</strong> and <strong>"My Membership"</strong> in the middle-left of the page. You can upgrade your account from there.`,
      },
      {
        title: 'How many BookChats can I start per month?',
        text: `As many as you’d like! We want you to read with your loved ones as much as possible.`,
      },
      {
        title: 'How many people can I connect with during a BookChat?',
        text: `BookChats can be a one-on-one experience, or you can have a multi-person BookChat with up to four other members.`,
      },
      {
        title:
          'How do I have a multi-person BookChat, or PartyChat as we like to call it?',
        text: `The paid subscriber will need to start a BookChat with one buddy and then other buddies can log into their Readeo accounts and click on the paid subscriber's name to add them to the BookChat. You’ll need to ensure that each user is connected to the paid user who started the BookChat.`,
      },
      {
        title: 'Can I read books from the library without being in a BookChat?',
        text: `Yes! Many of our members enjoy reading books from our Readeo library on their own, just as often as they like to BookChat together, and read out loud even when they’re far apart. Keep in mind that Free members can only read our 5 free books (which we swap out weekly), or preview the first few pages of any of our other books. Paid members can access our entire library.`,
      },
      {
        title: 'Is Readeo available in my country?',
        text: `Readeo is available to use in most countries. If you have any issues accessing our service, please reach out to our support team - support@readeo.com.`,
      },
    ],
  },
  {
    headingText: 'BookChat',
    items: [
      {
        title:
          'I don’t see my friend on my list. How do I invite them to a BookChat?',
        text: `Click <strong>“Add a BookChat Buddy”</strong> to search for or invite friends and family. If you searched for them by name or email and can’t find them, that probably means that they haven’t joined Readeo yet. Invite them by entering their email address or copying and sharing the custom link provided, and once they accept your invitation, they can enjoy Readeo for FREE!
        `,
      },
      {
        title: 'How do I know if I have successfully invited a BookChat Buddy?',
        text: `The person you invited should be listed on your profile page as a "Pending Request" if you entered their email. If you click on the three little dots by their name, you will have a choice to “Resend” or "Cancel Request". If you send them your custom link, you’ll need to resend the link to them as you will not see the request pending on your dashboard.
        `,
      },
      {
        title:
          'What if a BookChat Buddy has told me they accepted my invitation but I still see them as pending?',
        text: 'Try refreshing your page or logging out and signing back in.',
      },
      {
        title: 'How do I create a new bookshelf? (Paid members only)',
        text: `From your home page, click the <strong>“Bookshelves”</strong> tab found at the top right of the page and then click <strong>“Add a Bookshelf”</strong>. You can name the bookshelf anything you want. Many subscribers set up bookshelves for each of the children they read with! You can have as many bookshelves as you want with as many books as you’d like. When you are browsing in the library, you can click on any book and you will have the choice to either <strong>"Read"</strong> or <strong>"Add to Bookshelf"</strong>`,
      },
      {
        title:
          'How do I add books to the bookshelf I created? (Paid members only)',
        text: `When you’re browsing the library, you can click on any book and either <strong>"Read"</strong> or <strong>"Add to Bookshelf"</strong>. During a BookChat, on the last page of the book you will have the option to add it to a bookshelf as well.
        `,
      },
      {
        title:
          'Can my BookChat Buddies see the Bookshelves I created? (Paid members only)',
        text: `Yes! If you share your bookshelf, your BookChat buddies will be able to see it. If you set your bookshelf to “private,” only you will be able to view it. If it is shared, your BookChat buddies can go to the bookshelves tab and they will see your shared bookshelves.
        `,
      },
      {
        title: 'How do I delete books from my bookshelf? (Paid members only)',
        text: `
       You can remove books from your bookshelves by clicking on the <strong>"Bookshelves"</strong> tab in the upper right-hand corner of your home page. When you click on a book on a shelf that you created (not the recently read shelf), you will have the option to <strong>"Read"</strong> or <strong>"Remove"</strong> any book from that shelf. Please note that you cannot remove books from a bookshelf when you’re in a BookChat, only from your <strong>“Bookshelves”</strong> tab.
        `,
      },
      {
        title: 'How do I remove a bookshelf I created?',
        text: `You can delete a bookshelf by clicking on the "Bookshelf" tab in the upper right-hand corner of the page (between "Library" and "Help”). Then you will see a settings icon next to the title of the bookshelf; if you click on this, you will have the option to "Rename" or "Delete."
            `,
      },
      {
        title:
          'How do we access books in the library or on a bookshelf while in a BookChat?',
        text: `You can access the library or your bookshelves in a BookChat by clicking on the <strong>"library icon"</strong> on the top left of your screen, from there you can select your bookshelves or the library.
        `,
      },
      {
        title:
          'Is there a way to point things out to each other while in a BookChat?',
        text: `Yes! Just click on a spot in the book, and the pointer will appear. If you want to move it, you use your mouse to grab and drag it to the right spot.
        `,
      },
      {
        title: 'Is there a way to zoom in on the books?',
        text: `Yes! Just click on the magnifying glass on the left hand side of the screen if you’re on a computer. If you’re using a tablet or phone simply use your fingers to pinch and zoom. 
        `,
      },
      {
        title: 'How do I use the Bookmark feature? (Paid members only)',
        text: `Simply click on the Bookmark icon on the left-hand side of the screen while reading a book. This will allow you to save your spot and pick up right where you left off next time. To open a Bookmark, just click on the Bookmark button in the search menu and select the desired Bookmark.
        `,
      },
    ],
  },
  {
    headingText: 'Account',
    items: [
      {
        title: 'What should I do if I can’t log in?',
        text: `
            Your username is the email address you used to register your Readeo account when you signed up. 
            If you’re using the correct email address and your password isn’t working, 
            you can <a rel="noopener noreferrer" style="color:midnightblue;font-weight: bold" href="https://app.readeo.com/reset-password">reset your password here.</a> This will send a new password to your registered email address. If you don’t see the email within a few minutes, check your junk or spam folder. 
            <br />
            If you’re still having trouble accessing your account, please email us at <a style="color:midnightblue; font-weight: bold" href="mailto:help@readeo.com">help@readeo.com.</a> We’ll help you log in so you can use your account as soon as possible.
        `,
      },
      {
        title: 'How do I upload a profile picture?',
        text: `At this time, we don’t allow profile picture uploads. Please select from the library of avatars we’ve created for you!
        `,
      },
      {
        title: 'How do I change my password?',
        text: `Click on the <strong>“Profile”</strong> tab in the upper right-hand corner of your home page and then <strong>“My Account”</strong> in the dropdown. Under your account details over on the left of the screen, click the <strong>“Change Password”</strong> button. If you are unable to log into your account, you can reset your password using this <a target="_blank" rel="noopener noreferrer" style="color:midnightblue;font-weight:bold;text-decoration:underline" href="https://app.readeo.com/reset-password">link.</a>
        `,
      },
      {
        title: 'Where do I change billing information? (Paid members only)',
        text: `Click on the <strong>“Profile”</strong> tab in the upper right-hand corner of your home page and then <strong>“My Account”</strong> in the dropdown. Under your account details over on the left of the screen, click the <strong>“My Membership”</strong> button and enter your updated billing information.
        `,
      },
      {
        title: 'How often am I charged?',
        text: `You’ll be charged every month on the day you first signed up for monthly subscriptions, or once a year for annual subscriptions. For example, if you joined on February 6, you will be billed on the sixth of each month or each year on February 6, respectively. Accounts for those added to family plans or invited as guests are always free.
        `,
      },
      {
        title: 'How can I cancel my account?',
        text: `Click on the <strong>“Profile”</strong> tab in the upper right-hand corner of your homepage and then <strong>“My Account”</strong> in the dropdown. Under your account details over on the left of the screen, click the <strong>“My Membership”</strong> button and follow the prompts to cancel your account. If you are the paying subscriber and you cancel, everyone on your family plan will no longer have access to Readeo.
        Please <a style="color:midnightblue; font-weight: bold" href="mailto:help@readeo.com">let us know if you have any questions</a> before canceling!
            `,
      },
    ],
  },
  {
    headingText: 'Technology',
    items: [
      {
        title: 'What should I do if BookChat audio or video isn’t working?',
        text: `First, confirm you’re on the latest version of Readeo (if you’re not you’ll see a prompt near the top of your screen to refresh to the latest version) and that you’ve  completed the system check when logging in. You should be prompted on your first login to confirm that the basic components of your device are working - network connection, audio and video permissions, as well as being on the proper internet browser. If you’ve done the system check and are still having issues, please consider the following: \n
        
            <ol style="margin-left: 2rem; margin-bottom: 7px">
              <li style=" margin: 0 0 7px 0;">Make sure your speakers are turned on and that the volume is up.</li>
              <li style="margin: 0 0 7px 0;">Close other tabs or programs that may be running simultaneously.</li>
              <li style="margin: 0 0 7px 0;"Update your computer’s operating software and internet browser to ensure they’re both up to date.</li>
              <li style="margin: 0 0 7px 0;">We recommend using Chrome as your internet  browser for all laptops and desktops.</li>
              <li style="margin: 0 0 7px 0;">
                Try going into your settings to “allow” the mic and camera while using Chrome.
                <ul style="margin-left: 2rem;">
                  <li style="margin: 0 0 7px 0;">
                  You first must be logged into your Readeo account because these settings are specific to each website. In fact, we recommend you do this while in a BookChat.
                  </li>
                  <li style="margin: 0 0 7px 0;">
                  Next, click on the lock icon to the left of the address bar. A window will pop up with camera and microphone settings, and you can use the dropdown menu to "allow" camera and microphone access for Readeo. For Safari, you need to right click on the search bar and click “settings”.
                    </li>
                  <li style="margin: 0 0 7px 0;">
                    If the settings don't come up automatically, you can also click on "site settings" at the bottom of the pop-up to open the settings tab and “allow” Readeo access to your camera and microphone.
                  </li>
                 
                </ul>
              </li>
            </ol>
            
           <br />
           If you’ve gone through the list above and you’re still experiencing audio and/or visual issues, please email support@readeo.com. We’ll do our best to get your BookChat up and running in no time.
            `,
      },
      {
        title: 'Will Readeo work on my iPhone or iPad?',
        text: `Yes! You can navigate to our website and login to your account or you can download our mobile app on the App Store (and soon to be on the Google Play store)
        `,
      },
    ],
  },
];
