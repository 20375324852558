import React, { useState } from 'react';
import { Box, Flex, Button, Text, PseudoBox, Image } from '@chakra-ui/core';
import decoBorderImage from 'assets/images/deco_borders.svg';
import leftCandyCane from 'assets/images/left_candy_cane_sm.svg';
import rightCandyCane from 'assets/images/right_candy_cane_sm.svg';

import { StyledButton } from './StyledButton';
import { Plans } from './types';
import config from 'config';

export default function PricingPlansMobilePromo() {
  const [duration, setDuration] = useState<'monthly' | 'yearly'>('yearly');

  const [plan, setPlan] = useState<Plans>('family-gold');

  const deco_borders = `url(${decoBorderImage})`;

  const handleDuration = (duration: 'yearly' | 'monthly') =>
    setDuration(duration);

  const handlePlan = (plan: Plans) => setPlan(plan);
  const familyPlan = plan.includes('family');

  return (
    <Flex
      padding="0 1rem"
      flexDirection="column"
      backgroundColor="rgba(175, 220, 255, 0.3)"
      marginTop="0rem"
      paddingTop="5rem"
    >
      <Text fontSize="25px" textAlign="center" marginBottom="0.3rem">
        Choose Mobile Plan
      </Text>
      <Flex
        backgroundColor="#083261"
        padding="1rem"
        borderRadius="100px"
        height="50px"
        color="white"
        alignItems="center"
        justifyContent="space-evenly"
        mb="1rem"
      >
        <Button
          borderRadius="45px"
          width="45%"
          backgroundColor={duration !== 'yearly' ? '#019CD5' : 'transparent'}
          onClick={() => handleDuration('monthly')}
          _focus={{
            backgroundColor: duration !== 'yearly' ? '#019CD5' : 'transparent',
          }}
        >
          Monthly
        </Button>
        <Button
          borderRadius="45px"
          width="45%"
          backgroundColor={duration === 'yearly' ? '#019CD5' : 'transparent'}
          onClick={() => handleDuration('yearly')}
          _focus={{
            backgroundColor: duration === 'yearly' ? '#019CD5' : 'transparent',
          }}
        >
          Yearly
        </Button>
      </Flex>
      <Box>
        <Flex
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          marginTop={'1.2rem'}
        >
          <Image src={leftCandyCane} alt="left candy cane" />
          <Text
            fontWeight={600}
            lineHeight="24px"
            fontSize="18px"
            marginX="0.25rem"
          >
            Limited time holiday prices*
          </Text>
          <Image src={rightCandyCane} alt="right candy cane" />
        </Flex>
        <Text
          fontWeight={400}
          lineHeight="16px"
          font-size="14px"
          marginTop="0.5rem"
          textAlign="center"
          color="#2E2E2E"
        >
          *Get these special holiday prices on your first payment transaction!
        </Text>
      </Box>

      <div>
        <Flex
          backgroundColor={'white'}
          padding="1.4rem 1rem"
          margin="0.8rem 0"
          width="100%"
          cursor="pointer"
          justifyContent="space-between"
          alignItems="center"
          style={{
            background: familyPlan
              ? deco_borders + 'center/100% 100% no-repeat white'
              : 'linear-gradient(105.74deg, rgba(255, 255, 255, 0.8) 2.91%, rgba(255, 255, 255, 0.3) 90.22%)',
            borderRadius: familyPlan ? '24px' : '14px',
          }}
          onClick={() => handlePlan('family-gold')}
        >
          <Flex flexDirection="column" width="225px" justifyContent="center">
            <Text
              fontSize="21px"
              lineHeight="18px"
              fontWeight="600"
              marginBottom="0.5rem"
            >
              {' '}
              Gold Family Plan
            </Text>
            <Text fontSize="14px" fontWeight="500" lineHeight="17px">
              Share this plan with<br></br>4 others for them to enjoy the same
              Readeo Gold<br></br>
              benefits
            </Text>
            {duration === 'yearly' && (
              <Button
                borderRadius="14px"
                height="28px"
                border="1px solid #083261"
                backgroundColor="transparent"
                width="70%"
                marginTop="4%"
                fontWeight={700}
                fontSize="1rem"
              >
                Save 67%
              </Button>
            )}
          </Flex>
          <Flex
            flexDirection="column"
            padding="0.5rem 0"
            maxW="127px"
            color="#083261"
          >
            <Text
              fontSize="36px"
              lineHeight="16px"
              marginBottom="0.5rem"
              textTransform="uppercase"
              fontWeight="600"
              textAlign="center"
            >
              {duration === 'yearly' ? '$49.99' : '$7.49'}
            </Text>
            <Text
              fontWeight="bold"
              fontSize="18px"
              lineHeight="16px"
              marginBottom="2rem"
              letterSpacing="-0.01em"
              textAlign="center"
            >
              {duration === 'yearly' ? 'first year' : 'first month'}
            </Text>
            <PseudoBox
              position="relative"
              display="inline-block"
              _before={{
                position: 'absolute',
                content: `''`,
                top: '50%',
                left: '0%',
                width: '100%',
                height: '2px',
                backgroundColor: 'black',
                transform:
                  duration === 'yearly' ? 'rotate(36deg)' : 'rotate(15deg)',
              }}
              _after={{
                position: 'absolute',
                content: `''`,
                top: '50%',
                left: '0%',
                width: '100%',
                height: '2px',
                backgroundColor: 'black',
                transform:
                  duration === 'yearly' ? 'rotate(-36deg)' : 'rotate(-15deg)',
              }}
            >
              <Text
                fontSize="18px"
                lineHeight="16px"
                fontWeight="700"
                display="inline-block"
                color="#2E2E2E"
                whiteSpace="nowrap"
              >
                {duration === 'yearly' ? '$99.99' : '$14.99'}
                {duration === 'yearly' ? 'year' : 'Month'}
              </Text>
              {duration === 'yearly' && (
                <Text
                  fontSize="13px"
                  lineHeight="17px"
                  marginTop="0.25rem"
                  textAlign="left"
                >
                  $8.33 when
                  <br></br>
                  billed annually
                </Text>
              )}
            </PseudoBox>
          </Flex>
        </Flex>
      </div>
      <Box width="100%">
        <StyledButton
          textAlign="center"
          width="98%"
          height="50px"
          alignSelf="left"
          textTransform="uppercase"
          fontWeight="bold"
          fontSize="13pt"
          _hover={{
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #f38530',
          }}
          onClick={() => (window.location.href = `${config.appUrl}/signup`)}
        >
          START READING
        </StyledButton>
      </Box>
    </Flex>
  );
}
