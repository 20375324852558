import React from 'react';
import {
  Box,
  Flex,
  Grid,
  Image,
  Text,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PseudoBoxProps,
  PseudoBox,
} from '@chakra-ui/core';

import { Plans } from './types';
import { useIsLargeTabletView } from 'hooks/useIsLargeTabletView';

// assets
import candyCaneStraight from 'assets/images/candy_cane.svg';
import { ReactComponent as RedCancelMark } from 'assets/svg/red-cancel-icon.svg';
import { ReactComponent as GreenCheckMark } from 'assets/svg/green-check-icon.svg';
import { ReactComponent as Exclamation } from 'assets/svg/exclamation-mark.svg';
import { ReactComponent as QuestionMark } from 'assets/svg/question-mark.svg';

import config from 'config';

type PlanContentType = {
  text: (() => JSX.Element) | string;
  packages: [string, string?, string?];
  customMark?: CustomPlanCheck;
};

type ButtonStyle = Omit<PseudoBoxProps, 'size'>;
type CustomPlanCheck = Partial<Record<Plans | 'free', () => JSX.Element>>;

const buttonStyles: { [key in Plans]: ButtonStyle } = {
  free: {
    background: '#FFF',
    color: '#083261',
    border: '1px solid #019CD5',
    _hover: {
      background: '#FFF',
    },
  },
  'family-gold': {
    background: '#F38530',
    color: '#FFFFFF',
    _hover: {
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #f38530',
    },
  },
};
const plans = {
  free: {
    monthly: 'FREE',
    annually: 'Forever',
    d_monthly: 'STILL FREE',
    d_annually: 'Forever',
    discount: false,
  },
  'family-gold': {
    monthly: '$14.99/monthly',
    annually: '$99.99/annually',
    d_monthly: '$7.49 first month',
    d_annually: '$49.99 first year',
    discount: true,
  },
};

export default function ChoosePlanDesktop() {
  const isLargeTabletView = useIsLargeTabletView();

  const getButtonText = (plan: Plans) => {
    if (plan === 'free') return 'Stay Free';
    return 'Family Gold';
  };

  const togglePromotionDetails = process.env.REACT_APP_SALES_BANNER === 'show';

  const contents: PlanContentType[] = [
    {
      text: 'BookChat with the ones you love',
      packages: ['free', 'family-gold'],
    },

    {
      text: () => (
        <Box>
          <Text fontFamily="Montserrat">Full Library access</Text>
          {isLargeTabletView ? (
            <Text color="#2E2E2E" fontSize={12} opacity={0.5}>
              (over 1,000 books and counting!)
            </Text>
          ) : null}
        </Box>
      ),
      packages: ['free', 'family-gold'],
      customMark: {
        free: () => (
          <Box>
            Limited{' '}
            <Popover trigger="hover" placement="top">
              <PopoverTrigger>
                <QuestionMark style={{ display: 'inline' }} />
              </PopoverTrigger>
              <PopoverContent zIndex={100000} borderRadius="10px">
                <PopoverArrow />
                <PopoverBody textAlign="center" p="30px 20px">
                  Free members can only read our 5 free books (which we swap out
                  weekly), or preview the first few pages of any of our other
                  books
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Text fontSize={12} opacity={0.5}>
              (5 books rotate weekly)
            </Text>
          </Box>
        ),
      },
    },
    {
      text: () => (
        <Box>
          Create customized bookshelves
          <Popover trigger="hover" placement="top">
            <PopoverTrigger>
              <Exclamation
                style={{
                  display: 'inline',
                  marginLeft: '.25rem',
                  cursor: 'pointer',
                }}
              />
            </PopoverTrigger>
            <PopoverContent zIndex={100000} borderRadius="10px">
              <PopoverArrow />
              <PopoverBody textAlign="center" p="30px 20px">
                Create as many bookshelves as you want with as many books as you
                like. Plus, you can name them anything you wish – like 'Ryan's
                Bedtime Stories'.
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      ),
      packages: ['family-gold'],
    },
    {
      text: () => (
        <Box>
          Bookmarks
          <Popover trigger="hover" placement="top">
            <PopoverTrigger>
              <Exclamation
                style={{
                  display: 'inline',
                  marginLeft: '.25rem',
                  cursor: 'pointer',
                }}
              />
            </PopoverTrigger>
            <PopoverContent borderRadius="10px">
              <PopoverArrow />
              <PopoverBody textAlign="center" p="30px 20px">
                Bedtime always comes too soon — mark your place and pick up
                right where you left off, whenever!
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      ),
      packages: ['family-gold'],
    },
    {
      text: () => (
        <Box fontFamily="Montserrat">
          Share Gold with 4 others,
          <Popover trigger="hover" placement="top">
            <PopoverTrigger>
              <Exclamation
                style={{
                  display: 'inline',
                  marginLeft: '.25rem',
                  cursor: 'pointer',
                }}
              />
            </PopoverTrigger>
            <PopoverContent borderRadius="10px">
              <PopoverArrow />
              <PopoverBody textAlign="center" p="30px 20px">
                These members will have unlimited access to our entire library,
                can read solo or together during a BookChat, and invite guests
                outside of the Family Plan to BookChat for free!
              </PopoverBody>
            </PopoverContent>
          </Popover>
          {isLargeTabletView ? (
            <Text fontSize={12} color="#2E2E2E" opacity={0.5}>
              (They'll have full Gold plan benefits)
            </Text>
          ) : null}
        </Box>
      ),
      packages: ['family-gold'],
    },
  ];

  const BoxPlan = ({
    heading,
    plan,
    isSecond,
  }: {
    heading: string;
    plan: Plans;
    isSecond?: boolean;
  }) => {
    const price = plans[plan];

    const backgroundStyle = buttonStyles[plan];
    const renderCheck = (content: PlanContentType, plan: Plans) => {
      const isAvailable = content.packages.includes(plan);
      if (!isAvailable) return <RedCancelMark />;
      if (content.customMark?.[plan]) return content.customMark[plan]?.();
      return <GreenCheckMark />;
    };

    const goToSignUp = (plan: Plans) => {
      window.location.href = `${config.appUrl}/signup?plan=${plan}`;
    };
    return (
      <Box textAlign="center">
        <Text
          color="#083261"
          fontSize={!isLargeTabletView ? '1rem' : '1.125rem'}
          fontFamily="Montserrat"
          fontWeight="600"
          lineHeight="24px"
        >
          {heading}
        </Text>
        <Flex
          paddingX={!isLargeTabletView ? '1rem' : '1.3rem'}
          background="#FFFFFF"
          borderRadius="6px"
          flexDirection="column"
          justifyContent="space-between"
          paddingTop="1.25rem"
          paddingBottom="1.5rem"
          marginTop="16px"
          minHeight={togglePromotionDetails ? '625px' : '500px'}
        >
          <Flex flexDirection="column" alignItems="center">
            {contents.map((content, index) => (
              <Box
                height="70px"
                justifyContent="center"
                key={index}
                display="flex"
                alignItems="center"
              >
                {renderCheck(content, plan)}
              </Box>
            ))}
          </Flex>
          <Box>
            {(!togglePromotionDetails || !price.discount) && (
              <PseudoBox position="relative" display="inline-block">
                <Text
                  fontWeight="400"
                  fontSize="1.125rem"
                  letterSpacing="0.02em"
                  fontFamily="Montserrat"
                >
                  {price.monthly}
                </Text>
                <Text
                  fontWeight="600"
                  fontSize="1.125rem"
                  letterSpacing="0.02em"
                >
                  {price.annually}
                </Text>
              </PseudoBox>
            )}
            {togglePromotionDetails && price.discount && (
              <>
                <PseudoBox
                  marginTop=".5rem"
                  marginBottom=".5rem"
                  position="relative"
                  display="inline-block"
                  _before={{
                    position: 'absolute',
                    content: `''`,
                    top: '50%',
                    left: '0%',
                    width: '100%',
                    height: '2px',
                    backgroundColor: 'grey',
                    transform: 'rotate(15deg)',
                  }}
                  _after={{
                    position: 'absolute',
                    content: `''`,
                    top: '50%',
                    left: '0%',
                    width: '100%',
                    height: '2px',
                    backgroundColor: 'grey',
                    transform: 'rotate(-15deg)',
                  }}
                >
                  <Text
                    fontSize="18px"
                    lineHeight="24px"
                    display="inline-block"
                    color="#2E2E2E"
                    whiteSpace="nowrap"
                  >
                    <Text
                      fontSize="1.125rem"
                      letterSpacing="0.02em"
                      fontFamily="Montserrat"
                      display={plan === 'free' ? 'inline-block' : 'block'}
                      paddingRight={plan === 'free' ? '0.5rem' : '0 rem'}
                    >
                      {price.monthly}
                    </Text>

                    <Text
                      letterSpacing={!isLargeTabletView ? '0.01em' : '0.02em'}
                      fontFamily="Montserrat"
                      fontSize="1.125rem"
                      display={plan === 'free' ? 'inline-block' : 'block'}
                      color={plan === 'free' ? 'white' : 'inherit'}
                    >
                      {price.annually}
                    </Text>

                    {/* ${pricingPlans[`${duration}-family-gold` as PaymentPlans]?.price}
                  {duration === 'yearly' ? 'year' : 'Month'} */}
                  </Text>
                </PseudoBox>
                <Text
                  fontSize="1.125rem"
                  fontWeight="700"
                  letterSpacing="0.02em"
                  fontFamily="Montserrat"
                  display={plan === 'free' ? 'inline-block' : 'block'}
                  paddingRight={plan === 'free' ? '0.5rem' : '0 rem'}
                >
                  {price.d_monthly}
                </Text>
                <Text>or</Text>
                <Text
                  fontSize="1.125rem"
                  fontWeight="700"
                  letterSpacing="0.02em"
                  fontFamily="Montserrat"
                  display={plan === 'free' ? 'inline-block' : 'block'}
                  paddingRight={plan === 'free' ? '0.5rem' : '0 rem'}
                >
                  {price.d_annually}
                </Text>
              </>
            )}
          </Box>
          {/* <Box marginTop="0.5rem" marginBottom="0.5rem">
            <Text
              letterSpacing={!isLargeTabletView ? '0.01em' : '0.02em'}
              fontFamily="Montserrat"
              fontSize={!isLargeTabletView ? '13px' : 'initial'}
              color={plan === 'free' ? 'white' : 'red.600'}
              fontWeight="700"
            >
              {discountedPrice.monthly ? discountedPrice.monthly : '&nbsp;'}
            </Text>
            <Text
              letterSpacing={!isLargeTabletView ? '0.01em' : '0.02em'}
              fontFamily="Montserrat"
              fontSize={!isLargeTabletView ? '13px' : 'initial'}
              color={plan === 'free' ? 'white' : 'red.600'}
              fontWeight="700"
            >
              {discountedPrice.annually}
            </Text>
          </Box> */}
          <Button
            {...backgroundStyle}
            minWidth="20px"
            height={!isLargeTabletView ? '40px' : '50px'}
            textTransform="uppercase"
            borderRadius="6px"
            fontSize={!isLargeTabletView ? '13px' : '14px'}
            fontFamily="Montserrat"
            fontStyle="normal"
            fontWeight="bold"
            letterSpacing="0.01em"
            mt={5}
            onClick={() => {
              goToSignUp(plan);
            }}
          >
            {getButtonText(plan)}
          </Button>
        </Flex>

        {togglePromotionDetails && (
          <>
            {plan !== 'free' && (
              <Image
                src={candyCaneStraight}
                alt="candy cane straight"
                marginTop={'-0.9rem'}
              />
            )}
            {isSecond && (
              <Text
                whiteSpace="nowrap"
                marginLeft={isLargeTabletView ? '14%' : '0%'}
                marginTop="0.5825rem"
                fontWeight={400}
                fontSize="13px"
                lineHeight="24px"
                color="#000000"
              >
                *Get this special holiday price on your first payment
                transaction!
              </Text>
            )}
          </>
        )}
      </Box>
    );
  };

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      background={
        'linear-gradient(0deg, rgba(131, 208, 228, 0.5), rgba(131, 208, 228, 0.5)), #FFFFFF'
      }
      paddingTop="44px"
      paddingBottom="1.5rem"
      justifyContent="center"
    >
      <Flex justifyContent="center">
        <Grid
          gridTemplateColumns={!isLargeTabletView ? '27% 70%' : 'auto 800px'}
        >
          <Box pt="38px">
            <Flex
              paddingX="1.3rem"
              borderRadius="6px"
              flexDirection="column"
              paddingTop="1.25rem"
              paddingBottom="1.5rem"
              justifyContent="space-between"
            >
              <Flex flexDirection="column">
                {contents.map(({ text }, index) => (
                  <Box
                    height="70px"
                    key={index}
                    display="flex"
                    alignItems="center"
                  >
                    {typeof text === 'function' ? text() : text}
                  </Box>
                ))}
              </Flex>
              <Box height="51px" />
            </Flex>
          </Box>
          <Grid
            templateColumns={
              !isLargeTabletView ? 'repeat(3, 155px)' : 'repeat(3, 230px)'
            }
            gap={!isLargeTabletView ? 4 : 6}
            ml="1.25rem"
            pb="2rem"
          >
            <BoxPlan plan="free" heading="Free Plan" />
            <BoxPlan plan="family-gold" heading="Gold Family Plan" />
          </Grid>
        </Grid>
      </Flex>
      {/* <Button
        onClick={() => (window.location.href = `${config.appUrl}/signup`)}
        marginRight={!isLargeTabletView ? 'intial' : '-300px'}
        color="white"
        bg="#F38530"
        borderRadius="6px"
        width="279px"
        size="sm"
        height="50px"
        fontWeight="bold"
        fontSize="13pt"
        _hover={{
          background:
            'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #f38530',
        }}
      >
        GET STARTED FOR FREE
      </Button> */}
    </Flex>
  );
}
